<template>
  <div class="importBuilding">
    <import-template
      :otherParams="otherParams"
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
      :showCloseBtn="false"
      label="居民基本信息"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
import { _localStorage } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  name: "importBuilding",
  data() {
    return {
      downloadUrl: "/static/excel/人房关系导入.xlsx",
      uploadImgUrl: "/gateway/hc-portal/import/user",
      otherParams: {},
    };
  },
  components: {
    importTemplate,
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
  },
  mounted() {
    (this.otherParams.tenantId = this.accountInfo.tenantId),
      this.$setBreadList("居民信息导入");
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.importBuilding {
  height: calc(100% - 98px);
  box-sizing: border-box;
  background: #fff;
  ::v-deep .content {
    margin-left: 20px;
    justify-content: flex-start;
  }
}
</style>
